<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 服装列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-delete"
                        class="handle-del mr10"
                        @click="delAllSelection"
                >批量管理
                </el-button>
                <el-select v-model="query.categoryId" placeholder="分类" class="handle-select mr10"
                           @change="_categoryOptionChange">
                    <el-option :label="item.categoryName" :value="item.id" v-for="item in topCategories"
                               :key="item.id"></el-option>
                </el-select>
                <el-select v-model="query.status" placeholder="服装状态" class="handle-select mr10"
                           @change="_shelfStatusOptionChange">
                    <el-option label="上架" value="0">上架</el-option>
                    <el-option label="下架" value="1"></el-option>
                </el-select>
                <el-input v-model="query.name" placeholder="关键字" class="handle-input mr10"
                          prefix-icon="el-icon-search"></el-input>
                <!--<el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>-->
            </div>
            <el-table
                    :data="clothingData.records"
                    border

                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="45" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="45" align="center">
                    <template #default="scope">
                        <el-tag
                                :type="
                              scope.row.shelfStatus === 0
                                    ? 'success'
                                    : scope.row.shelfStatus === 1
                                    ? 'danger'
                                   : ''">{{ scope.row.id}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column label="副标题" prop="subtitle"></el-table-column>
                <el-table-column label="头像(查看大图)" align="center">
                    <template #default="scope">
                        <el-image
                                class="table-td-thumb"
                                :src="scope.row.coverThumbnailUrl"
                                :preview-src-list="[scope.row.coverUrl]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="分类" width="80">
                    <template #default="scope">
                        <div> {{_getCategoryNameById(scope.row.categoryId)}}</div>
                    </template>

                </el-table-column>
                <el-table-column label="属性" align="center">
                    <template #default="scope">
                        <el-button
                                type="primary"
                                plain
                                size="mini"
                                icon="el-icon-s-order"
                                @click="_handleParamEdit(scope.$index, scope.row)"
                        >属性编辑
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="详情图片" align="center">
                    <template #default="scope">
                        <el-button
                                type="primary"
                                plain
                                size="mini"
                                icon="el-icon-picture-outline"
                                @click="_handlePicEdit(scope.$index, scope.row)"
                        >编辑
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column label="创建时间" width="100">
                    <template #default="scope">
                        <div> {{scope.row.createTime?scope.row.createTime.split(" ")[0]:scope.row.createTime}}</div>
                        <div> {{scope.row.createTime?scope.row.createTime.split(" ")[1]:scope.row.createTime}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="_handleBaseEdit(scope.$index, scope.row)"
                        >编辑
                        </el-button>
                        <el-button
                                v-if="(!query.status)||query.status==0"
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="_handleDelete(scope.row.id, scope.row)"
                        >下架
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="clothingData.current"
                        :page-size="clothingData.size"
                        :total="clothingData.total"
                        @current-change="_handlePageChange"
                ></el-pagination>
            </div>
        </div>


        <!-- 基本编辑弹出框 -->
        <el-dialog title="编辑" v-model="editBaseVisible">
            <v-base-edit @nextStep="_saveEdit" :current-clothing="currentEditItem" step-title="确认编辑"></v-base-edit>
        </el-dialog>

        <!-- 属性编辑弹出框 -->
        <el-dialog title="编辑" v-model="editParamVisible" width="70%">
            <v-param-edit @nextStep="_saveEdit" :product-id="currentEditItem.id"
                          step-title="确认编辑"></v-param-edit>
        </el-dialog>
        <!-- 基本编辑弹出框 -->
        <el-dialog title="编辑" v-model="editPicVisible" width="60%">
            <v-image-upload @nextStep="_saveEdit" :product-id="currentEditItem.id"
                            step-title="确认编辑"></v-image-upload>
        </el-dialog>

    </div>
</template>

<script>
    import api from '../api/api'
    import {ElMessage} from 'element-plus';
    import vBaseEdit from "../components/ClothingBaseEdit";
    import vImageUpload from "../components/ClothingImageUpload";
    import vParamEdit from "../components/ClothingParamEdit";

    export default {
        name: "ClothingList",
        components: {
            vBaseEdit,
            vImageUpload,
            vParamEdit
        },
        data() {
            return {
                currentEditItem: undefined,
                query: {
                    categoryId: undefined,
                    isTopLevel: true,
                    current: 1,
                    size: 6
                },
                categories: [],
                topCategories: [],
                clothingData: {},
                multipleSelection: [],
                delList: [],
                editBaseVisible: false,
                editParamVisible: false,
                editPicVisible: false,
                pageTotal: 0,
                form: {},
                idx: -1,
                id: -1
            };
        },
        created() {
            this._getClothingCategories();
            this._getClothingList();
        },
        methods: {
            /**
             *  分类选项发生变化
             */
            _categoryOptionChange() {
                this._getClothingList();
            },
            /**
             *  分类选项发生变化
             */
            _shelfStatusOptionChange() {
                this._getClothingList();
            },
            /**
             * 获取服装分类信息
             * @private
             */
            _getClothingCategories() {
                api.get("/categories")
                    .then(res => {
                        if (res.code === 0) {
                            let data = res.data;
                            if (data.total === 0)
                                return;
                            this.categories = data.records;
                            this.topCategories = this._getTopCategories(data.records);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            /**
             *  通过Id获取分类名称
             */
            _getCategoryNameById(categoryId) {
                let target = undefined;
                this.categories.forEach(item => {
                    if (item.id === categoryId) {
                        target = item;

                    }
                })
                if (target)
                    return target.categoryName;
            },
            /**
             *  获取顶层分类
             */
            _getTopCategories(data, targerId = 0) {
                let targetArr = [];
                data.forEach(item => {
                    if (item.parentId === targerId) {
                        targetArr.push(item);
                    }
                });
                return targetArr;
            },
            /**
             *
             * @private
             */
            _getClothingList() {
                api.get("/clothes", {params: this.query})
                    .then(res => {
                        console.log(res);
                        if (res.code === 0) {
                            this.clothingData = res.data;
                            console.log(this.clothingData.records)
                        } else {
                            ElMessage({type: 'error', message: res.message})
                        }
                        console.log("clothingData", this.clothingData)
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, "current", 1);
                this.getData();
            },
            // 删除操作
            _handleDelete(index) {
                // 二次确认删除
                this.$confirm("确定要下架吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        api.delete("/clothing/" + index)
                            .then(res => {
                                console.log(res);
                                if (res.code === 0) {
                                    ElMessage({type: 'success', message: "下架成功"})
                                    this._getClothingList();
                                }
                            }).catch(err => {
                            console.log(err);
                        });

                    })
                    .catch(() => {
                    });
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            /**
             * 批量删除
             */
            delAllSelection() {
                // const length = this.multipleSelection.length;
                // let str = "";
                // this.delList = this.delList.concat(this.multipleSelection);
                // for (let i = 0; i < length; i++) {
                //     str += this.multipleSelection[i].name + " ";
                // }
                // this.$message.error(`删除了${str}`);
                // this.multipleSelection = [];
            },
            /**
             * 服装基本信息编辑
             * @param index
             * @param row
             * @private
             */
            _handleBaseEdit(index, row) {
                this.currentEditItem = {
                    "id": row.id, "title": row.title, "subtitle": row.subtitle
                    , "coverUrl": row.coverUrl
                    , "categoryId": row.categoryId
                };
                this.editBaseVisible = true;
            },
            /**
             * 服装属性编辑
             * @param index
             * @param row
             * @private
             */
            _handleParamEdit(index, row) {
                this.currentEditItem = {
                    "id": row.id, "title": row.title, "subtitle": row.subtitle
                    , "coverUrl": row.coverUrl
                    , "categoryId": row.categoryId
                };
                console.log("_handleParamEdit", row.id)
                this.editParamVisible = true;
            },
            /**
             * 服装详情图片编辑
             * @param index
             * @param row
             * @private
             */
            _handlePicEdit(index, row) {
                console.log("_handlePicEdit", row);
                this.currentEditItem = {
                    "id": row.id
                };
                this.editPicVisible = true;
            },
            // 保存编辑
            _saveEdit() {
                this.editBaseVisible = false;
                this.editPicVisible = false;
                this.editParamVisible = false;
                this._getClothingList();
            },
            // 分页导航
            _handlePageChange(val) {
                this.query.current = val;
                this._getClothingList();
            }
        }
    }
    ;
</script>

<style scoped>

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
        text-align: center;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .el-dialog {
        min-width: 600px !important;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>
